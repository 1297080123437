import {useNavigate} from "react-router";
import React, {useEffect, useState} from "react";
import { useStore } from "../store/store";
import { useTranslation } from "react-i18next";
import {When} from "react-if";
import {observer} from "mobx-react";
import {DebugType} from "../components/model/Snackbar";
import {parsePermissionByName, UserPermission} from "../components/model/Permission";
import {Input} from "@material-tailwind/react";
import {
    RiAddCircleLine as PlusIcon,
    RiHistoryLine as HistoryLineIcon,
} from '@remixicon/react'
import {SuccessButton} from "../components/ui/SuccessButton";
import {UserHistoryItem} from "../components/UserHistoryItem";
import {DangerButton} from "../components/ui/DangerButton";
import {MigrateAccount} from "../components/modal/MigrateAccount";
import {NoAccess} from "../components/NoAccess";
import {AddBadge} from "../components/modal/AddBadge";
import {SelectBadgeHistory} from "../components/modal/SelectBadgeHistory";
import {BadgeItem} from "../components/BadgeItem";

export const ManageUser: React.FC = observer(() => {
    const store = useStore();
    const navigate = useNavigate();
    const [manageUser, setManageUser]: any | undefined = useState(undefined);
    const [openMigrate, setOpenMigrate] = useState(false);
    const [openAddBadge, setOpenAddBadge] = useState(false);
    const [openHistoryBadge, setOpenHistoryBadge] = useState(false);
    const [loadingManageUser, setLoadManageUser] = useState(false);
    const [perm, setPerm] = useState(0);
    const [id, setId] : any = useState(null);
    const { t, i18n } = useTranslation();
    const initManageUser = (id: string) => {
        setId(id);
        loadManageUser();
    }
    const loadManageUser = () => {
        if(id == null){
            store.addSnackbar({
                message: {
                    pl: "Bez podanego ID nie możesz zarządzać użytkownikiem",
                    en: 'Without set ID cannot manage any user'
                },
                type: DebugType.ERROR,
                duration: 10000
            })
            return;
        }
        setLoadManageUser(true);
        store.loadProfile(id, false).then(e=> {
            setManageUser(e);
            setPerm(e.permission);
            store.addHistoryUser({
                id: e.id,
                name: e.username,
                avatarURL: `https://cdn.discordapp.com/avatars/${e.id}/${e.avatar}`
            })
        }).finally(()=> setLoadManageUser(false));
    }
    const onCheck = (target : any, perms : UserPermission) => {
        if(target.target.checked) {
            setPerm(perm + perms);
        }else{
            setPerm(perm - perms);
        }
    }
    useEffect(()=> {
        store.setLoading(false);
    }, [])
    const addBadge = (badge: {emoji: string, emoji_name: string, name: {en: string, pl: string}, type: string}) => {
        if(manageUser == null)return;
        let user = Object.assign({}, manageUser);
        user.badges.push(badge);
        store.addHistoryBadge(badge)
        setManageUser(user);
    }
    const removeBadge = (badge: any) => {
        let user = Object.assign({}, manageUser);
        user.badges = user.badges.filter((e: any)=> e !=badge);
        setManageUser(user);
    }
    const setgCoins = (d: number)=> {
        let user = Object.assign({}, manageUser);
        user.gCoins = d;
        setManageUser(user);
    }
    const save = () => {
        const data = {
            gCoins: manageUser.gCoins,
            permission: perm,
            badges: manageUser.badges
        }
        store.manageUser(id, data);
    }
    if(store.loading) return <></>
    return (
        <>
            <div>
                <section className="xl:overflow-hidden select-none flex flex-col min-h-screen text-white">
                    <div className={"flex flex-col items-center text-xl h-max text-white font-montserrat top-40"}>
                        {(store.user.permission & 8388608) === 8388608 ? (
                            <div className={"h-full w-screen relative"}>
                                <div className={"mt-28 mx-5 xl:mx-48"}>
                                    <div className={"text-2xl xl:text-4xl font-bold mt-10"}>Zarządzanie użytkownikiem
                                    </div>
                                    <div className={"w-full h-1 bg-gray-700 mb-5 mt-5"}></div>
                                    <div
                                        className={"flex flex-row items-center justify-between xl:justify-center xl:space-x-10 mt-10"}>
                                        <div className={"w-2/3 xl:w-full"}>
                                            <Input type={'number'}
                                                   labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                   label={"Identyfikator użytkownika"} color={'blue'} crossOrigin={''}
                                                   disabled={store.user && (store.user.permission & 8388608) != 8388608}
                                                   onBlur={(e: any) => {
                                                       setId(e.target.value);
                                                   }} onPaste={(e: any) => {
                                                setId(e.target.value);
                                            }} className="text-white "/>
                                        </div>
                                        <div className={"flex flex-row text-right items-end justify-end"}>
                                            <SuccessButton label={"Edytuj"} press={() => {
                                                loadManageUser();
                                            }} loading={loadingManageUser}/>
                                        </div>
                                    </div>
                                    <When condition={manageUser === undefined}>
                                        <div className={"text-2xl xl:text-4xl font-bold mt-10"}>Lub wybierz z twojej
                                            historii
                                        </div>
                                        <div className={'grid xl:grid-cols-4 grid-cols-2 gap-2 xl:gap-4 w-full mt-10'}>
                                            {store.history.manageUsers.map((e: any) => <UserHistoryItem key={e.id}
                                                manage={initManageUser} user={e}/>)}
                                        </div>
                                    </When>
                                    <When condition={manageUser != undefined}>
                                        <div className={"mt-10 text-xl"}>Zarządzasz
                                            użytkownikiem <b>{manageUser != undefined ? manageUser.username : ''}</b>
                                        </div>
                                        <div className={"w-full h-[1px] bg-gray-700 mb-5 mt-5"}></div>
                                        <div className={"grid grid-cols-1 xl:grid-cols-2 w-full xl:space-x-10"}>
                                            <div>
                                                <div className={"w-full"}>
                                                    <Input type={'number'}
                                                           value={manageUser != undefined ? manageUser.gCoins : 0}
                                                           labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                           label={"Stan gCoinów"} color={'blue'} crossOrigin={''}
                                                           disabled={store.user && (store.user.permission & 262144) != 262144}
                                                           onInput={(e: any) => setgCoins(e.target.value)}
                                                           className="text-white "/>
                                                    <div className={'mb-5'}></div>
                                                    <Input type={'number'}
                                                           value={manageUser != undefined ? perm : 0}
                                                           labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                           label={"Uprawnienia"} color={'blue'} crossOrigin={''}
                                                           disabled={store.user && (store.user.permission & 32768) != 32768}
                                                           className="text-white "/>
                                                    <div className={'flex justify-between items-center'}>
                                                        <div className={"text-xl font-bold mt-5 mb-5"}>Odznaki</div>
                                                        <When
                                                            condition={store.user && (store.user.permission & 65536) == 65536}>
                                                            <div className={'flex space-x-2'}>
                                                                <div className={'cursor-pointer'}
                                                                     onClick={() => setOpenHistoryBadge(!openHistoryBadge)}>
                                                                    <HistoryLineIcon color={'orange'}/>
                                                                </div>
                                                                <div className={'cursor-pointer'}
                                                                     onClick={() => setOpenAddBadge(!openAddBadge)}>
                                                                    <PlusIcon color={'green'}/>
                                                                </div>
                                                            </div>
                                                        </When>
                                                    </div>
                                                    {manageUser != undefined ? manageUser.badges.map((e: any) =>
                                                        <BadgeItem key={e.emoji} badge={e}
                                                                   remove={() => removeBadge(e)}/>) : {}}
                                                    <div className={"text-xl font-bold mt-5 mb-5"}>Akcje</div>
                                                    <div className={"space-y-3"}>
                                                        <SuccessButton label={'Otwórz profil'} press={() => {
                                                        }} loading={false}/>
                                                        <When
                                                            condition={(store.user.permission & UserPermission.WRITE_USER_RESET_COOLDOWNS) === UserPermission.WRITE_USER_RESET_COOLDOWNS}><DangerButton
                                                            label={'Zresetuj limity ekonomii'} press={() => {
                                                            store.resetCooldowns(manageUser.id)
                                                        }} loading={false}/></When>
                                                        <When
                                                            condition={(store.user.permission & UserPermission.WRITE_USER_MIGRATE) === UserPermission.WRITE_USER_MIGRATE}><DangerButton
                                                            label={'Migracja danych na inne konto'} press={() => {
                                                            setOpenMigrate(true);
                                                        }} loading={false}/></When>
                                                        <MigrateAccount open={openMigrate} setOpen={setOpenMigrate}/>
                                                        <When
                                                            condition={(store.user.permission & UserPermission.WRITE_USER_PURGE) === UserPermission.WRITE_USER_PURGE}><DangerButton
                                                            label={'Skasuj wszystkie dane (Po wykonaniu akcji dane przepadają)'}
                                                            press={() => {
                                                            }} loading={false}/></When>
                                                        <SuccessButton label={'Zapisz'} press={() => {
                                                            save();
                                                        }} loading={false}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"w-full"}>
                                                <div className={"font-bold text-lg"}>Kalkulator uprawnień</div>
                                                <div className={"mb-5 text-sm text-gray-500"}>Aktualny poziom uprawnień
                                                    wynosi <span className={"text-white font-bold"}>{perm}</span></div>
                                                <pre className={'grid grid-cols-1 xl:grid-cols-3 gap-2 xl:gap-4'}>
                                                    {Object.keys(UserPermission).filter(e => isNaN(Number(e))).map((e: any) => (<>

                                                        <div className="flex items-center mb-4" key={e}>
                                                            <input id={`checkbox-${e}`} type="checkbox"
                                                                   disabled={(store.user.permission & UserPermission.WRITE_USER_PERMISSIONS) != UserPermission.WRITE_USER_PERMISSIONS}
                                                                   onChange={d => onCheck(d, parsePermissionByName(e)!)}
                                                                   checked={((perm & parsePermissionByName(e)!) === parsePermissionByName(e))}
                                                                   className="w-5 h-5 text-blue-600 bg-red-500 border-red-300 rounded focus:ring-blue-600 focus:ring-1"/>
                                                            <label htmlFor={`checkbox-${e}`}
                                                                   className="ms-2 text-sm font-medium text-white">{e}</label>
                                                        </div>
                                                    </>))}
                                                </pre>
                                            </div>
                                        </div>
                                    </When>
                                </div>
                            </div>
                        ) : <NoAccess/>}
                    </div>
                </section>
            </div>
            <AddBadge open={openAddBadge} setOpen={setOpenAddBadge} add={(e: any) => addBadge(e)}/>
            <SelectBadgeHistory open={openHistoryBadge} setOpen={setOpenHistoryBadge} add={(e: any) => addBadge(e)}/>
        </>
    );
});
