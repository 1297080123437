import React from "react";
import {action, computed, flow, makeObservable, observable} from "mobx";
import {DebugType, Snackbar} from "../components/model/Snackbar";
import {wait} from "../utils/global";

export class Store {
  @observable showAside: boolean;
  @observable apiURL: string;
  @observable token?: any;
  @observable user: any;
  @observable profile?: any;
  @observable lang: string;
  @observable snackbar: Snackbar[];
  @observable history: any;
  @observable fastLogin: boolean = false;
  @observable loading: boolean = false;

  constructor() {
    makeObservable(this)
    this.showAside = false;
    this.apiURL = 'https://api.mysterdead.xyz/v1';
    // this.apiURL = 'http://localhost:3001/v1';
    this.snackbar = [];
    this.profile=null;
    this.lang = localStorage.getItem("lang") ?? "pl";
    if(localStorage.getItem('user') !=null){
      this.addSnackbar({
        message: {
          en: "User data is loaded from cache data. You are only see offline data.",
          pl: "Dane użytkownika zostały załadowane z danych cache. Aktualnie są dostępne w trybie offline"
        },
        type: DebugType.DEBUG,
        duration: 0,
        hide: true
      })
      this.user = JSON.parse(localStorage.getItem("user")!);
    }
    if(localStorage.getItem('token') !=null){
      this.addSnackbar({
        message: {
          en: "Authorization token is now loaded from cache data",
          pl: "Token logowania został przywrócony z danych cache"
        },
        type: DebugType.DEBUG,
        duration: 0,
        hide: true
      })
      this.token = localStorage.getItem("token");
    }
    if(localStorage.getItem('fastLogin') !=null){
      this.addSnackbar({
        message: {
          en: `The fastlogin is now ${localStorage.getItem('fastLogin') == 'true' ? 'enabled' : 'disabled'}`,
          pl: `Funkcja szybkiego logowania jest aktualnie ${localStorage.getItem('fastLogin') == 'true' ? 'włączona' : 'wyłączona'}`
        },
        type: DebugType.DEBUG,
        duration: 0,
        hide: true
      })
      this.fastLogin = localStorage.getItem("fastLogin") == 'true';
    }
    if(this.history == null){
      const localData = localStorage.getItem('history');
      if(localData ==null){
        this.addSnackbar({
          message: {
            en: "Cache history is initialized",
            pl: 'Historia została zainicjowana'
          },
          type: DebugType.DEBUG,
          duration: 0,
          hide: true
        })
        const data : any = {
          manageUsers: [],
          badges: [],
        }
        this.history = data;
        localStorage.setItem('history', JSON.stringify(data));
      }else{
        this.addSnackbar({
          message: {
            en: "Cache history is loaded",
            pl: "Historia ostatnich użytkowników została załadowana z danych cache"
          },
          type: DebugType.DEBUG,
          duration: 0,
          hide: true
        })
        this.history = JSON.parse(localData);
      }
    }
    if(this.user !=null){
      //Run override lang from user
      this.loadLocalUser();
    }
  }

  @action
  setLoading(data: boolean) {
    this.addSnackbar({
      message: {
        en: `Loading page with global component`,
        pl: `Ładowanie strony przy użyciu globalnego componentu`
      },
      type: DebugType.DEBUG,
      duration: 0,
      hide: true
    })
    this.loading = data;
  }

  @action
  setFastLogin(data: boolean) {
    this.addSnackbar({
      message: {
        en: `The fastlogin is now ${data ? 'enabled' : 'disabled'}`,
        pl: `Funkcja szybkiego logowania jest aktualnie ${data ? 'włączona' : 'wyłączona'}`
      },
      type: DebugType.DEBUG,
      duration: 0,
      hide: true
    })
    this.fastLogin = data;
    localStorage.setItem('fastLogin', String(data));
  }

  @flow
  *addSnackbar(snackbar: any) {
    switch (snackbar.type){
      case DebugType.INFO:
        console.log(`\u001B[35m[\u001B[32mINFO\u001B[35m] \u001B[0m`, snackbar.message[this.lang]);
        break;
      case DebugType.WARN:
        console.log(`\u001B[35m[\u001B[33mWARN\u001B[35m] \u001B[0m`, snackbar.message[this.lang]);
        break;
      case DebugType.ERROR:
        console.log(`\u001B[35m[\u001B[31mERROR\u001B[35m] \u001B[0m`, snackbar.message[this.lang]);
        break;
      case DebugType.DEBUG:
        console.log(`\u001B[35m[\u001B[34mDEBUG\u001B[35m] \u001B[0m`, snackbar.message[this.lang]);
        break;
    }
    if(!snackbar.hide){
      snackbar.hide = false;
    }
    snackbar.timestamp = Date.now();
    this.snackbar.push(snackbar);
    setTimeout(()=> {
      this.snackbar.map(e=> {
        if(e.timestamp == snackbar.timestamp){
          e.hide = true;
        }
      })
    }, snackbar.duration);
  }

  @action
  addHistoryUser(data: {id: string, name: string, avatarURL: string}){
    console.log(this.history.manageUsers.find((e: any) => e.id === data.id))
    if(this.history.manageUsers.find((e: any) => e.id === data.id) === undefined){
      this.history.manageUsers.push(data);
      localStorage.setItem('history', JSON.stringify(this.history))
    }
  }

  @action
  addHistoryBadge(data: {emoji: string, emoji_name: string, name: {en: string, pl: string}, type: string}) {
    if(this.history.badges.find((e: any) => e.emoji === data.emoji && e.name.pl === data.name.pl) === undefined){
      this.history.badges.push(data);
      localStorage.setItem('history', JSON.stringify(this.history))
    }
  }

  @action
  removeSnackbar(snack: Snackbar){
    this.snackbar.splice(this.snackbar.indexOf(snack), 1)
  }


  @action
  async loadLocalUser(){
    try{
      const response = await fetch(`${this.apiURL}/user/@me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        },
      })
      const json = await response.json();
      this.setUser(json.data);
      this.setLanguage(this.user.preferred_locale);
    }catch (e){
      this.logout();
      store.addSnackbar({
        message: {
          en: 'Session expired! You are been logout',
          pl: "Sesja wygasła! Zostałeś wylogowany/a"
        },
        type: DebugType.ERROR,
        duration: 50000
      })
    }
  }

  @action
  setLanguage(lang: string) {
    this.lang = lang;
    store.addSnackbar({
      message: {
        en: 'Język się zmienił na '+lang,
        pl: "The language is changed to "+lang
      },
      type: DebugType.DEBUG,
      duration: 0,
      hide: true
    })
    localStorage.setItem("lang", lang);
  }

  @action
  async getEmojis() {
    const response = await fetch(`${store.apiURL}/admin/emojis`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    try{
      const json = await response.json();
      return json.data
    }catch (e) {
      store.addSnackbar({
        message: {
          en: 'Nie można pobrać listy dostępnych emoji z Discord',
          pl: "Cannot fetch available emoji list from Discord"
        },
        type: DebugType.ERROR,
        duration: 5000,
      })
      return null;
    }
  }


  @action
  async searchProfiles(search: string) {
    try{
      const response = await fetch(`${store.apiURL}/user/@me/search?content=${search}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        },
      })
      const json = await response.json();
      return json.data
    }catch (e) {
      store.addSnackbar({
        message: {
          en: 'Nie udało się pobrać danych z API',
          pl: "Cannot fetch data from API"
        },
        type: DebugType.DEBUG,
        duration: 0,
        hide: true
      })
      this.addSnackbar({
        message: {
          pl: "Przepraszamy! Aktualnie nasze serwery są w trakcie konserwacji.",
          en: 'Sorry! The our servers now in maintenance'
        },
        type: DebugType.ERROR,
        duration: 10000
      })
      return [];
    }
  }

  @action
  async getDiscordData(id: string) {
    const response = await fetch(`${store.apiURL}/user/${id}/discord`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    try{
      const json = await response.json();
      return json.data
    }catch (e) {
      store.addSnackbar({
        message: {
          en: 'Nie udało się pobrać danych z API',
          pl: "Cannot fetch data from API"
        },
        type: DebugType.DEBUG,
        duration: 0,
        hide: true
      })
      store.addSnackbar({
        message: {
          en: 'Nie można pobrać danych użytkownika Discord',
          pl: "Cannot fetch discord user data"
        },
        type: DebugType.ERROR,
        duration: 5000,
      })
      return null;
    }
  }

  @action
  async getPlannedMessage(id: string) {
    const response = await fetch(`${store.apiURL}/admin/planned-message/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    try{
      const json = await response.json();
      return json.data
    }catch (e) {
      store.addSnackbar({
        message: {
          en: 'Nie udało się pobrać danych z API',
          pl: "Cannot fetch data from API"
        },
        type: DebugType.DEBUG,
        duration: 0,
        hide: true
      })
      store.addSnackbar({
        message: {
          en: 'Nie można pobrać zaplanowanej wiadomości',
          pl: "Cannot fetch planned message"
        },
        type: DebugType.ERROR,
        duration: 5000,
      })
      return null;
    }
  }

  @action
  async getPlannedMessages() {
    const response = await fetch(`${store.apiURL}/admin/planned-message`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    try{
      const json = await response.json();
      return json.data
    }catch (e) {
      store.addSnackbar({
        message: {
          en: 'Nie udało się pobrać danych z API',
          pl: "Cannot fetch data from API"
        },
        type: DebugType.DEBUG,
        duration: 0,
        hide: true
      })
      store.addSnackbar({
        message: {
          en: 'Nie można pobrać listy zaplanowanych wiadomości',
          pl: "Cannot fetch planned messages"
        },
        type: DebugType.ERROR,
        duration: 5000,
      })
      return null;
    }
  }

  @action
  async getChannels() {
    const response = await fetch(`${store.apiURL}/admin/channels`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    try{
      const json = await response.json();
      return json.data
    }catch (e) {
      store.addSnackbar({
        message: {
          en: 'Nie udało się pobrać danych z API',
          pl: "Cannot fetch data from API"
        },
        type: DebugType.DEBUG,
        duration: 0,
        hide: true
      })
      store.addSnackbar({
        message: {
          en: 'Nie można pobrać listy dostępnych kanałow z Discord',
          pl: "Cannot fetch available channels list from Discord"
        },
        type: DebugType.ERROR,
        duration: 5000,
      })
      return null;
    }
  }

  @action
  async getRoles() {
    const response = await fetch(`${store.apiURL}/admin/roles`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    try{
      const json = await response.json();
      return json.data
    }catch (e) {
      store.addSnackbar({
        message: {
          en: 'Nie udało się pobrać danych z API',
          pl: "Cannot fetch data from API"
        },
        type: DebugType.DEBUG,
        duration: 0,
        hide: true
      })
      store.addSnackbar({
        message: {
          en: 'Nie można pobrać listy dostępnych ról z Discord',
          pl: "Cannot fetch available role list from Discord"
        },
        type: DebugType.ERROR,
        duration: 5000,
      })
      return null;
    }
  }

  @action
  async updateConfiguration(id: string, data: any): Promise<any> {
    const response = await fetch(`${store.apiURL}/admin/guilds/${id}/settings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
      body: JSON.stringify({variables: data})
    })
    const json = await response.json();
    if(json.statusCode !=200){
      this.addSnackbar({
        message: {
          pl: "Ups! Nie udało nam się zapisać ustawień tego serwera",
          en: 'Sorry! We cannot save this settings for the selected guild'
        },
        type: DebugType.ERROR,
        duration: 10000
      })
      return null;
    }
    this.addSnackbar({
      message: {
        pl: "Ustawienia serwera zaktualizowane",
        en: 'The guild settings might be saved'
      },
      type: DebugType.INFO,
      duration: 10000
    })
    return json.data;
  }

  @action
  async getLeaderboard(property: string, page: number) {
    const response = await fetch(`${store.apiURL}/leaderboard?property=${property}&page=${page}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    try{
      const json = await response.json();
      return json.data
    }catch (e) {
      store.addSnackbar({
        message: {
          en: 'Nie udało się pobrać danych z API',
          pl: "Cannot fetch data from API"
        },
        type: DebugType.DEBUG,
        duration: 0,
        hide: true
      })
      store.addSnackbar({
        message: {
          en: 'Nie można pobrać listy rankingu',
          pl: "Cannot fetch leaderboard"
        },
        type: DebugType.ERROR,
        duration: 5000,
      })
      return null;
    }
  }

  @action
  async getReportedBug(type: string) {
    const response = await fetch(`${store.apiURL}/bug?status=${type}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    try{
      const json = await response.json();
      return json.data
    }catch (e) {
      store.addSnackbar({
        message: {
          en: 'Nie udało się pobrać danych z API',
          pl: "Cannot fetch data from API"
        },
        type: DebugType.DEBUG,
        duration: 0,
        hide: true
      })
      store.addSnackbar({
        message: {
          en: 'Nie można pobrać listy zgłoszonych błędów',
          pl: "Cannot fetch reported bugs list"
        },
        type: DebugType.ERROR,
        duration: 5000,
      })
      return null;
    }
  }


  @action
  async getConfiguration(id: string) {
    const response = await fetch(`${store.apiURL}/admin/guilds/${id}/settings`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    try{
      const json = await response.json();
      return json.data
    }catch (e) {
      store.addSnackbar({
        message: {
          en: 'Nie udało się pobrać danych z API',
          pl: "Cannot fetch data from API"
        },
        type: DebugType.DEBUG,
        duration: 0,
        hide: true
      })
      store.addSnackbar({
        message: {
          en: 'Nie można pobrać listy dostępnych kanałow z Discord',
          pl: "Cannot fetch available channels list from Discord"
        },
        type: DebugType.ERROR,
        duration: 5000,
      })
      return null;
    }
  }

  @action
  async cancelPlannedMessage(id: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/admin/planned-message/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${this.token}`
      },
    })
    const json = await response.json();
    if(json.statusCode !=200){
      this.addSnackbar({
        message: {
          pl: "Ups! Nie udało nam anulować zaplanowanej wiadomości",
          en: 'Sorry! Cannot cancel planned message'
        },
        type: DebugType.ERROR,
        duration: 10000
      })
      return null;
    }
    this.addSnackbar({
      message: {
        pl: "Wysyłka wiadomości została anulowana",
        en: 'The planned message is cancelled'
      },
      type: DebugType.INFO,
      duration: 10000
    })
    return json.data;
  }


  @action
  async sendNow(id: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/admin/planned-message/${id}`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${this.token}`
      },
    })
    const json = await response.json();
    if(json.statusCode !=200){
      this.addSnackbar({
        message: {
          pl: "Ups! Nie udało nam wysłać zaplanowanej wiadomości",
          en: 'Sorry! We cannot force send this planned message'
        },
        type: DebugType.ERROR,
        duration: 10000
      })
      return null;
    }
    this.addSnackbar({
      message: {
        pl: "Wiadomość została wysłana",
        en: 'The planned message is sent'
      },
      type: DebugType.INFO,
      duration: 10000
    })
    return json.data;
  }


  @action
  async resetCooldowns(id: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/admin/cooldowns?from=${id}`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${this.token}`
      },
    })
    const json = await response.json();
    if(json.statusCode !=200){
      this.addSnackbar({
        message: {
          pl: "Ups! Nie udało nam się zresetować czasu oczekiwania w ekonomii",
          en: 'Sorry! We cannot reset the economy cooldowns for this user'
        },
        type: DebugType.ERROR,
        duration: 10000
      })
      return null;
    }
    this.addSnackbar({
      message: {
        pl: "Czas oczekiwania w ekonomii został zresetowany",
        en: 'The economy cooldowns for this user might be rested now'
      },
      type: DebugType.INFO,
      duration: 10000
    })
    return json.data;
  }

  @action
  async editPlannedMessage(id: string, data: {content?: string, embed?: any, channel: string, deliveryDate: Date, attachments?: [], reactions?: any, link?: string}): Promise<any> {
    const response = await fetch(`${store.apiURL}/admin/planned-message/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
      body: JSON.stringify(data)
    })
    const json = await response.json();
    if(json.statusCode !=200){
      this.addSnackbar({
        message: {
          pl: "Ups! Nie udało nam edytować twojej wiadomości",
          en: 'Sorry! We cannot edit your message to sent in time'
        },
        type: DebugType.ERROR,
        duration: 10000
      })
      return null;
    }
    this.addSnackbar({
      message: {
        pl: "Wiadomość została edytowana",
        en: 'The message is edited now'
      },
      type: DebugType.INFO,
      duration: 10000
    })
    return json.data;
  }

  @action
  async planMessage(data: {content?: string, embed?: any, channel: string, deliveryDate: Date, attachments?: [], reactions?: any, link?: string, poll: any}): Promise<any> {
    const formdata = new FormData();
    if(data.content)formdata.append('content', data.content);
    if(data.embed)formdata.append('embed', JSON.stringify(data.embed));
    if(data.poll)formdata.append('poll', JSON.stringify(data.poll));
    if(data.channel)formdata.append('channel', data.channel);
    if(data.deliveryDate)formdata.append('deliveryDate', data.deliveryDate.toString());
    if(data.reactions)formdata.append('reactions', data.reactions);
    if(data.link)formdata.append('link', data.link);
    if(data.attachments){
      data.attachments.forEach((e)=> {
        formdata.append('attachments', e)
      });
    }
    const response = await fetch(`${store.apiURL}/admin/planned-message`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.token}`
      },
      body: formdata
    })
    const json = await response.json();
    if(json.statusCode !=200){
      this.addSnackbar({
        message: {
          pl: "Ups! Nie udało nam zaplanować twojej wiadomości",
          en: 'Sorry! We cannot plan your message to sent in time'
        },
        type: DebugType.ERROR,
        duration: 10000
      })
      return null;
    }
    this.addSnackbar({
      message: {
        pl: "Wiadomość została zaplanowana",
        en: 'The message is planned now'
      },
      type: DebugType.INFO,
      duration: 10000
    })
    return json.data;
  }

  @action
  async updateReportBug(id: string, status: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/bug/${id}?status=${status}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${this.token}`
      },
    })
    const json = await response.json();
    if(json.statusCode !=200){
      this.addSnackbar({
        message: {
          pl: "Ups! Nie udało nam zmienić statusu zgłoszenia",
          en: 'Sorry! We cannot change reported bug status'
        },
        type: DebugType.ERROR,
        duration: 10000
      })
      return null;
    }
    this.addSnackbar({
      message: {
        pl: "Zmieniono status zgłoszenia",
        en: 'Reported bug status might be changed'
      },
      type: DebugType.INFO,
      duration: 10000
    })
    return json.data;
  }

  @action
  async reportBug(data: {description: string, platform: string}): Promise<any> {
    const response = await fetch(`${store.apiURL}/bug`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
      body: JSON.stringify(data)
    })
    const json = await response.json();
    if(json.statusCode !=200){
      this.addSnackbar({
        message: {
          pl: "Ups! Nie udało nam wysłać twojego zgłoszenia",
          en: 'Sorry! We cannot send your report'
        },
        type: DebugType.ERROR,
        duration: 10000
      })
      return null;
    }
    this.addSnackbar({
      message: {
        pl: "Zgłoszenie zostało wysłane",
        en: 'The report might be sent'
      },
      type: DebugType.INFO,
      duration: 10000
    })
    return json.data;
  }

  @action
  async manageUser(id: string, data: {permission?: number, gCoins?: number, badges?: any}): Promise<any> {
    const response = await fetch(`${store.apiURL}/admin/manage/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
      body: JSON.stringify(data)
    })
    const json = await response.json();
    if(json.statusCode !=200){
      this.addSnackbar({
        message: {
          pl: "Ups! Nie udało nam się edytować danych tego użytkownika",
          en: 'Sorry! We cannot modyfi this user data'
        },
        type: DebugType.ERROR,
        duration: 10000
      })
      return null;
    }
    this.addSnackbar({
      message: {
        pl: "Edytowano dane użytkownika",
        en: 'The user data is now updated'
      },
      type: DebugType.INFO,
      duration: 10000
    })
    return json.data;
  }

  @action
  async loadProfile(ide: string, fullProfile: boolean = true) {
    let id = ide;
    if(this.user !=null && ide === this.user.id){
      id = '@me'
    }
    const response = await fetch(`${store.apiURL}/user/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    try{
      const json = await response.json();
      let data = json.data;
      if(!fullProfile) return data;
      this.profile = data;
      return this.profile;
    }catch (e) {
      store.addSnackbar({
        message: {
          en: 'Nie udało się pobrać danych z API',
          pl: "Cannot fetch data from API"
        },
        type: DebugType.DEBUG,
        duration: 0,
        hide: true
      })
      return null;
    }
  }

  @action
  async loadLeaderboardRankLevel(id: string, property: string): Promise<number> {
    const response = await fetch(`${store.apiURL}/user/${id}/rank?property=${property}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    const json = await response.json();
    return json.data;
  }

  @action
  async loadTransactions(id: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/user/${id}/transactions`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    const json = await response.json();
    if(json.statusCode !=200){
      return [];
    }
    return json.data;
  }

  @action
  async downloadRaportMonth(year: string, month: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/admin/raport/month?year=${year}&month=${month}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.token}`
      },
    })
    response.blob().then(blob => URL.createObjectURL(blob))
        .then(url => {
          const a = document.createElement("a");
          a.href = url;
          a.setAttribute('download', `Raport-${year}-${month}.xlsx`);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        });
  }

  @action
  async downloadRaportYear(year: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/admin/raport/year?year=${year}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.token}`
      },
    })
    response.blob().then(blob => URL.createObjectURL(blob))
        .then(url => {
          const a = document.createElement("a");
          a.href = url;
          a.setAttribute('download', `Raport-${year}.xlsx`);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        });
  }

  @action
  async downloadModeratorRaport(id: string, year: string, month?: any): Promise<any> {
    const response = await fetch(`${store.apiURL}/admin/raport/user/${id}?year=${year}${month !=null ? `&month=${month}` : ""}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.token}`
      },
    })
    response.blob().then(blob => URL.createObjectURL(blob))
        .then(url => {
          const a = document.createElement("a");
          a.href = url;
          a.setAttribute('download', month !=null ? `Raport-${id}-${month}-${year}.xlsx` : `Raport-${id}-${year}.xlsx`);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        });
  }

  async loadRaportDetails(param: string, moderator?: string | undefined): Promise<any> {
    const response = await fetch(`${store.apiURL}/admin/raport?props=${param}${moderator !=null ? `&moderator=${moderator}` : ''}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    });
    try {
      const json = await response.json();
      if(json.statusCode !=200){
        return null;
      }
      console.log(json)
      return json.data;
    }catch (e){
      return null;
    }
  }

  @action
  async loadWarns(id: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/user/${id}/history?type=WARN`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    const json = await response.json();
    if(json.statusCode !=200){
      return [];
    }
    return json.data;
  }


  @action
  async saveLeavelCard(card: {background_image?: string, background_color: string, primary_text_color: string, secondary_text_color: string, progress_fill_color: string, progress_tint_color: string}): Promise<any> {
    const response = await fetch(`${store.apiURL}/user/@me/card`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
      body: JSON.stringify(card)
    })
    const json = await response.json();
    if(json.statusCode !=200){
      this.addSnackbar({
        message: {
          pl: "Ups! Nie udało nam się zapisać twojej karty poziomu",
          en: 'Sorry! We cannot save your rank card'
        },
        type: DebugType.ERROR,
        duration: 10000
      })
      return null;
    }
    this.addSnackbar({
      message: {
        pl: "Karta poziomu została zaktualizowana",
        en: 'The rank card might be saved now'
      },
      type: DebugType.INFO,
      duration: 10000
    })
    return json.data;
  }

  @action
  async loadMutes(id: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/user/${id}/history?type=TIMEOUT`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    const json = await response.json();
    if(json.statusCode !=200){
      return [];
    }
    return json.data;
  }

  @action
  async loadBans(id: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/user/${id}/history?type=BAN`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    const json = await response.json();
    if(json.statusCode !=200){
      return [];
    }
    return json.data;
  }

  get calculateProgress() {
    const cx = this.user.xp;
    const rx = this.user.xpRequired;
    return (cx * 100) / rx;
  }

  @action
  async loadKick(id: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/user/${id}/history?type=KICK`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    const json = await response.json();
    if(json.statusCode !=200){
      return [];
    }
    return json.data;
  }

  existUser = () : boolean => {
    return this.user != null;
  }

  setToken = (token: any) => {
    this.token = token;
    if(token == null){
      localStorage.removeItem("token");
    }else{
      localStorage.setItem("token", token);
    }
  }

  @action
  setUser = (user: any) => {
    this.user = user;
    if(user == null){
      localStorage.removeItem("user");
    }else{
      localStorage.setItem("user", JSON.stringify(user));
    }
  }

  /**
   * ! showAside - Show sidebar
   */
  @action
  showAsideToggle() {
    this.showAside = !this.showAside;
    console.log(this.showAside);
  }

  @action
  logout(){
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    this.user = null;
    this.token = null;
  }
}
const StoreContext = React.createContext<Store>({} as Store);
export const store = new Store();

export const StoreProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  return (
    <StoreContext.Provider value={store}> {children} </StoreContext.Provider>
  );
};

export const useStore = () => React.useContext(StoreContext);
