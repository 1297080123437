import React, {useEffect, useState} from "react";
import { useStore } from "../store/store";
import { useTranslation } from "react-i18next";
import {observer} from "mobx-react";
import {parseSnackbarTypeColor} from "../components/model/Snackbar";
import {NoAccess} from "../components/NoAccess";
import {When} from "react-if";
import * as en from '../lang/en-EN.json'
import * as pl from '../lang/pl-PL.json'
import {RiCornerDownRightLine as RightIcon} from '@remixicon/react'
import {RiText as TextIcon} from '@remixicon/react'
import {Option, Select} from "@material-tailwind/react";


export const DeveloperPage: React.FC = observer(() => {
    const store = useStore();
    const { t, i18n } = useTranslation();
    const [page, setPage] = useState(0);
    const [lang, setLang] = useState('pl')
    if(store.user == null){
        return <NoAccess/>;
    }
    const checkType = (object: any, checkType: string) => {
        return (typeof object) == checkType;
    }
    useEffect(()=> {
        store.setLoading(false);
    }, [])
    if(store.loading) return <></>;
    const buildTranslationTree = (en: any) => {
        return <>
            {Object.keys(en).map((d: string) => <>
                <div className={'font-bold'}>{d}</div>
                <When condition={checkType(en[d], 'string')}>
                    <div className={'text-xs flex space-x-1 items-center ml-4'}>
                        <TextIcon size={'16px'}/>
                        <div className={'text-gray-500'}>{en[d]}</div>
                    </div>
                </When>
                <When condition={checkType(en[d], 'object')}>
                    {Object.keys(en[d]).map((e: string) => <>
                        <div className={'flex space-x-1 text-sm ml-4'}>
                            <RightIcon size={'18px'}/>
                            <div>{e}</div>
                        </div>
                        <When condition={checkType(en[d][e], 'string')}>
                            <div className={'text-xs flex space-x-1 items-center ml-9'}>
                                <TextIcon size={'16px'}/>
                                <div className={'text-gray-500'}>{en[d][e]}</div>
                            </div>
                        </When>
                        <When condition={checkType(en[d][e], 'object')}>
                            {Object.keys(en[d][e]).map((r: string) => <>
                                <div className={'flex space-x-1 text-sm ml-9'}>
                                    <RightIcon size={'18px'}/>
                                    <div>{r}</div>
                                </div>
                                <When condition={checkType(en[d][e][r], 'string')}>
                                    <div className={'text-xs flex space-x-1 items-center ml-14'}>
                                        <TextIcon size={'16px'}/>
                                        <div className={'text-gray-500'}>{en[d][e][r]}</div>
                                    </div>
                                </When>
                                <When condition={checkType(en[d][e][r], 'object')}>
                                    {Object.keys(en[d][e][r]).map((s: string) => <>
                                        <div className={'flex space-x-1 text-sm ml-16'}>
                                            <RightIcon size={'18px'}/>
                                            <div>{s}</div>
                                        </div>
                                        <When condition={checkType(en[d][e][r][s], 'string')}>
                                            <div className={'text-xs flex space-x-1 items-center ml-20'}>
                                                <TextIcon size={'16px'}/>
                                                <div className={'text-gray-500'}>{en[d][e][r][s]}</div>
                                            </div>
                                        </When>
                                    </>)}
                                </When>
                            </>)}
                        </When>
                    </>)}
                </When>
            </>)}
        </>
    }
    return (
        <>
            <div>
                <section className="xl:overflow-hidden select-none flex flex-col min-h-screen text-white">
                    <div className={"flex flex-col items-center h-full text-xl text-white font-montserrat top-40"}>
                        {(
                            <div className={"h-full w-screen relative"}>
                                <div className={"mt-28 mx-5 xl:mx-28"}>
                                    <div className={"text-4xl font-bold"}>Panel Developera</div>
                                    <div className={"w-full h-1 bg-gray-700 mb-5 mt-5"}></div>
                                    <div className={'flex flex-col md:flex-row md:justify-evenly text-blue-500'}>
                                        <div onClick={()=> setPage(0)} className={page == 0 ? 'text-white' : 'cursor-pointer'}>Konsola Debug</div>
                                        <div onClick={()=> setPage(1)} className={page == 1 ? 'text-white' : 'cursor-pointer'}>Tłumaczenie</div>

                                    </div>
                                    <When condition={page == 0}>
                                        <div className={'mx-16 rounded-lg p-5'}>
                                            {store.snackbar.map((e: any) => (<>
                                                <div className={'flex flex-col'}>
                                                    <div className={'flex flex-row space-x-2 items-center'}>
                                                        <div
                                                            className={`${parseSnackbarTypeColor(e.type)} rounded-lg text-xs p-2`}>{e.type}</div>
                                                        <div
                                                            className={'text-sm text-gray-200'}>{new Date(e.timestamp).toLocaleTimeString()}</div>
                                                    </div>
                                                    <div>{e.message[i18n.language]}</div>
                                                </div>
                                            </>))}
                                        </div>
                                    </When>
                                    <When condition={page == 1}>
                                        <div className={'mx-16 rounded-lg p-5'}>
                                            <Select className={'text-white font-montserrat'}
                                                    labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                    menuProps={{className: 'bg-black'}}
                                                    color={'blue'}
                                                    value={lang}
                                                    label={'Wybierz język'}
                                                    onChange={(e: any) => setLang(e)}>
                                                <Option value={'pl'}>Polski</Option>
                                                <Option value={'en'}>Angielski</Option>
                                            </Select>
                                            {buildTranslationTree(lang == 'pl' ? pl : en)}
                                        </div>
                                    </When>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </div>
        </>
    );
});
