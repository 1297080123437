import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Input, Option, Select,
    Textarea, Tooltip,
    Typography
} from "@material-tailwind/react";
import React, {useState} from "react";
import MarkdownPreviewExample from "../message/MarkdownPreview";
import {RiAddCircleLine as PlusIcon, RiEmojiStickerLine, RiCloseCircleLine} from "@remixicon/react";
import {Else, If, Then, When} from "react-if";
import EmojiPicker from "emoji-picker-react";
import {useTranslation} from "react-i18next";

export const CreatePoll = (props: {open: boolean, setOpen: Function, title: string, description: string, setPoll: Function}) => {

    const handleOpen = () => props.setOpen(!props.open);
    const [pickEmoji, setPickEmoji] = useState(false);
    const { t, i18n } = useTranslation();
    const [context, setContext] = useState({
        allow_multiselect: false,
        question: {
            text: 'Tytuł ankiety'
        },
        answers: [
            {
                index: 0,
                text: "Opcja 1",
                emoji: {
                    name: '👍'
                }
            },
            {
                index: 1,
                text: "Opcja 2",
                emoji: {
                    name: '👎'
                }
            },
        ] as any,
        duration: 32,
        layout_type: 1,
    });
    const addAnswere = () => {
        let card = Object.assign({}, context);
        if(card.answers.length == 0){
            card.answers.push({
                index: 0,
                text: "Nowa opcja",
            });
        }else {
            card.answers.push({
                index: card.answers[card.answers.length - 1].index+1,
                text: "Nowa opcja",
            });
        }
        setContext(card)
    }
    const save = () => {
        props.setPoll(context);
        handleOpen();
    }
    const updateTitle = (text: string) => {
        let card = Object.assign({}, context);
        card.question.text = text;
        setContext(card)
    }
    const updateDuration = (text: number) => {
        let card = Object.assign({}, context);
        card.duration = text;
        setContext(card)
    }
    const updateOptionText = (selectedIndex: number, text: string) => {
        let card = Object.assign({}, context);
        let m : any = card.answers.find((e: any)=> e.index === selectedIndex);
        if(m){
            m.text = text;
        }
        setContext(card)
    }
    const updateOptionEmoji = (selectedIndex: number, text: string) => {
        let card = Object.assign({}, context);
        let m : any = card.answers.find((e: any)=> e.index === selectedIndex);
        if(m){
            if(m.emoji == undefined) m.emoji = {};
            m['emoji']['name']=text;
            setPickEmoji(false);
        }
        setContext(card)
    }
    const deleteOption = (selectedIndex: any) => {
        let card = Object.assign({}, context);
        console.log(card.answers)
        let m : any = card.answers.find((e: any)=> e.index === selectedIndex);
        console.log(selectedIndex, m)
        let d = card.answers.filter((test: any) => test !=m);
        card.answers = d;
        setContext(card)
    }
    return (<>
        <Dialog open={props.open} handler={handleOpen} className={"bg-[#111111] overscroll-y-contain"} size={"md"}>
            <DialogHeader className={"text-white font-montserrat text-3xl"}>{props.title}</DialogHeader>
            <DialogBody className={'text-white'}>
                <div className={'mb-5'}>{props.description}</div>
                <div className="space-y-5">
                    <Input type={'text'} labelProps={{className: 'text-white peer-placeholder-shown:text-white'}} label={"Tytuł ankiety"} color={'blue'} crossOrigin={''}
                           value={context.question.text}
                           onInput={(e: any) => {
                               updateTitle(e.target.value);
                           }}
                           onBlur={(e: any) => {
                               updateTitle(e.target.value);
                           }} onPaste={(e: any) => {
                        updateTitle(e.target.value);
                    }} className="text-white "/>
                    <Select className={'text-white'}
                            onChange={(e: any) => updateDuration(Number(e))}
                            labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                            color={'blue'}
                            label={'Wybierz czas trwania ankiety'}>
                        {[24, 72, 168, 336, 720].map((e: any)=> <Option value={e} className={'flex items-center gap-2'}>{t('plannedMessage.time.' + e)}</Option>)}
                    </Select>
                    <div className={'flex items-center justify-between'}>
                        <div>Odpowiedzi</div>
                        <Tooltip content={'Dodaj nową odpowiedź'} className={'z-10'} placement={'bottom-start'}>
                            <div onClick={()=> addAnswere()} className={'cursor-pointer'}><PlusIcon color={'green'}/></div>
                        </Tooltip>
                    </div>
                    {context.answers.map((d: any, index: number) => <>
                        <div className={'flex items-center space-x-2'} key={index}>
                            <If condition={d.emoji == null}>
                                <Then>
                                    <div className={'cursor-pointer'} onClick={()=> setPickEmoji(true)}><RiEmojiStickerLine/></div>
                                    <div className={'fixed z-10'}>
                                        <EmojiPicker open={pickEmoji} onEmojiClick={(e) => updateOptionEmoji(d.index, e.emoji)}/>
                                    </div>
                                </Then>
                                <Else>
                                    <div>{d.emoji !=null ? d.emoji.name ?? 'brak' : 'brak'}</div>
                                </Else>
                            </If>
                            <Input type={'text'} labelProps={{className: 'text-white peer-placeholder-shown:text-white'}} label={"Opcja #"+(index+1)} color={'blue'} crossOrigin={''}
                                   value={d.text}
                                   onInput={(e: any) => {
                                       updateOptionText(d.index, e.target.value);
                                   }}
                                   onBlur={(e: any) => {
                                       updateOptionText(d.index, e.target.value);
                                   }} onPaste={(e: any) => {
                                updateOptionText(d.index, e.target.value);
                            }} className="text-white "/>
                            <Tooltip content={'Usuń odpowiedź'} className={'z-10'} placement={'bottom-start'}>
                                <div onClick={()=> deleteOption(d.index)} className={'cursor-pointer'}><RiCloseCircleLine color={'red'}/></div>
                            </Tooltip>
                        </div>
                    </>)}
                </div>
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    color="red"
                    onClick={handleOpen}
                    className="mr-1"
                >
                    <span>Anuluj</span>
                </Button>
                <Button variant="gradient" color="green" onClick={save}>
                    <span>Utwórz ankiete</span>
                </Button>
            </DialogFooter>
        </Dialog>
    </>);
}
