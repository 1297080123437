import React, {useEffect, useState} from "react";
import { useStore } from "../store/store";
import { pl } from "react-day-picker/locale";
import {observer} from "mobx-react";
import {Input, Option, Popover, PopoverContent, PopoverHandler, Select} from "@material-tailwind/react";
import { JSX } from "react/jsx-runtime";
import {DayPicker} from "react-day-picker";
import {format} from "date-fns";
import {When} from "react-if";
import {Calendar} from "../components/navigation/Calendar";
import {wait} from "../utils/global";
import {DangerButton} from "../components/ui/DangerButton";
import {MessageVisualization} from "../components/message/MessageVisualization";
import {SuccessButton} from "../components/ui/SuccessButton";
import {NoAccess} from "../components/NoAccess";


export const PlannedMessage: React.FC = observer(() => {
    const store = useStore();
    const [date, setDate] = React.useState<Date>();
    const [time, setTime] = useState('00:00');
    const [embed2, setEmbed2] :any = useState({
        "content" : undefined,
        "embed": undefined,
        poll: undefined,
        attachments: [],
        reactions: [],
        link: [],
    });
    const [plannedMessages, setPlannedMessages] :any = useState([]);
    const [channels, setChannels] : any[] = useState([])
    const [channel, setChannel] = useState(null);
    useEffect(() => {
        store.setLoading(true)
        if(channels.length == 0){
            store.getChannels().then(e => {
                e.sort((e: any, d: any) => e.position - d.position);
                setChannels(e);
            }).finally(() => {
                store.getPlannedMessages().then(e=> {
                    setPlannedMessages(e);
                }).finally(()=> {
                    store.setLoading(false);
                })
            });
        }
    }, []);
    const updateAttachments = (attachments: any[]) => {
        let card = Object.assign({}, embed2);
        card.attachments = attachments;
        setEmbed2(card);
    }
    const updateReactions = (reactions: any[]) => {
        let card = Object.assign({}, embed2);
        card.reactions = reactions;
        setEmbed2(card);
    }
    const save = () => {
        let local = new Date();
        local.setHours(Number(time.split(":")[0]), Number(time.split(":")[1]));
        local.setDate(date!.getDate())
        local.setMonth(date!.getMonth())
        local.setFullYear(date!.getFullYear())
        setDate(local);
        console.log(date)
        store.planMessage({
            content: embed2.content,
            embed: embed2.embed,
            deliveryDate: local,
            poll: embed2.poll,
            channel: channel!,
            attachments: embed2.attachments,
            reactions: JSON.stringify(embed2.reactions),
            link: JSON.stringify(embed2.link),
        }).then(()=> {
            store.getPlannedMessages().then(e=> {
                setPlannedMessages(e);
            });
            setEmbed2({
                content: undefined,
                embed: undefined,
                attachments: [],
                reactions: [],
                link: []
            });
            setTime('00:00');
            setDate(null!);
        })
    }
    const updateMessage = (value: string) => {
        let card = Object.assign({}, embed2);
        card.content = value;
        setEmbed2(card)
    }
    const updateLink = (value: string) => {
        let card = Object.assign({}, embed2);
        card.link = value;
        setEmbed2(card)
    }
    const updatePoll = (prop?: any) => {
        let card = Object.assign({}, embed2);
        card.poll = prop;
        setEmbed2(card)
    }
    const updateMessageEmbed = (prop?: string, value?: string, subTree?: string) => {
        console.log(subTree, prop, value)
        let card = Object.assign({}, embed2);
        if(prop == null && !value && !subTree){
            card.embed = undefined;
        }else if((value !=null && prop !=null) || value ==null && prop !=null){
            if(!card.embed) card.embed = {};
            if(subTree !=null){
                let subtreedata = Object.assign({}, card.embed[subTree]);
                subtreedata[prop!] = value;
                card.embed[subTree] = subtreedata;
            }else {
                card.embed[prop!] = value;
            }
        }else {
            if(prop == null){
                card.embed = undefined;
            }else {
                card.embed = {};
            }
        }
        setEmbed2(card)
    }
    const buildOptions = () => {
        let option: JSX.Element[] = [];
        const d = channels.filter((e: any) => e.type == 4).sort((e: any, d: any) => e.rawPosition - d.rawPosition);
        d.forEach((e: any)=> {
            option.push(<Option value={e.id} disabled={true}>{e.name}</Option>)
            const m = channels.filter((x:any) => x.parentId === e.id);
            m.sort((e :any,d :any) => e.rawPosition - d.rawPosition );
            m.map((e:any) => option.push(<Option value={e.id}>{e.name}</Option>))
        })
        return option;
    }
    if(store.loading) return <></>
    return (
        <>
            <div>
                <section className="xl:overflow-hidden select-none flex flex-col min-h-screen text-white">
                    <div className={"flex flex-col items-center text-xl h-max text-white font-montserrat top-40"}>
                        {(store.user.permission & 1073741824) === 1073741824 ? (
                            <div className={"h-full w-screen relative"}>
                                {date !=null ? (<>
                                    <div className={"mt-28 mx-5 xl:mx-48"}>
                                        <div className={"text-2xl xl:text-4xl font-bold mt-10"}
                                             id={'build-message'}>Planowanie wysyłki
                                            wiadomości
                                        </div>
                                        <div className={'text-red-500 text-xl font-bold mt-5'}>DEV NOTE</div>
                                        <div className={'text-sm'}>Ta funkcja jest w trakcie rozwoju wszelkie błędy
                                            proszę
                                            bezpośrednio zgłaszać do mysterdead przez platformę Discord
                                        </div>
                                        <div className={'flex space-x-1 text-xs'}>
                                            <span>Ta strona obsługuje uprawnienie</span>
                                            <span className={'font-bold'}>1073741824</span>
                                        </div>
                                        <div className={'space-y-5 xl:space-y-10 mt-10'}>
                                            <div className={'grid grid-cols-1 xl:grid-cols-3 gap-2'}>
                                                <div>
                                                    <Select className={'text-white'}
                                                            labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                            color={'blue'}
                                                            label={'Wybierz kanał do wysyłki'}
                                                            onChange={(e: any) => setChannel(e)}>
                                                        {buildOptions()}
                                                    </Select>
                                                </div>
                                                <Input
                                                    className={'text-black bg-transparent'}
                                                    labelProps={{className: 'text-white peer-placeholder-shown:text-black'}}
                                                    label="Wybierz date"
                                                    crossOrigin={''}
                                                    containerProps={{className: 'disabled:bg-black'}}
                                                    value={date != null ? format(date!, "PPP", {locale: pl}) : ''}
                                                />
                                                <Input
                                                    className={'text-white'}
                                                    labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                    label="Wybierz czas"
                                                    type={'time'}
                                                    min={'00:00'}
                                                    max={'23:59'}
                                                    onInput={(e: any) => setTime(e.target.value)}
                                                    crossOrigin={''}
                                                    defaultValue={time}
                                                />
                                            </div>
                                            <div className={'grid grid-cols-1 xl:grid-cols-2 gap-2'}>
                                                <MessageVisualization edit={false} data={embed2}
                                                                      updateEmbed={updateMessageEmbed}
                                                                      updateContent={updateMessage}
                                                                      updateAttachments={updateAttachments}
                                                                      updateReactions={updateReactions}
                                                                      updatePoll={updatePoll}
                                                                      updateLink={updateLink}/>
                                                <div>
                                                    {/*     <pre>*/}
                                                    {/*    {JSON.stringify(embed2, null, 2)}*/}
                                                    {/*</pre>*/}
                                                    <When condition={channel != null}>
                                                        <div className={'flex space-x-2 items-center'}>
                                                            <span>Twoja wiadomość pojawi się na kanale </span>
                                                            <a href={`https://discord.com/channels/784111179479515198/${channel}`}
                                                               className={'font-bold text-blue-500'}>{channels.find((e: any) => e.id == channel)?.name}</a>
                                                        </div>
                                                    </When>
                                                    <When condition={channel != null && date != null}>
                                                        <div className={'flex space-x-2 items-center'}>
                                                            <span>Zostanie ona upubliczniona dnia </span>
                                                            <span
                                                                className={'font-bold'}>{date?.toLocaleDateString()}</span>
                                                        </div>
                                                    </When>
                                                    <When condition={channel != null && date != null}>
                                                        <div className={'flex space-x-2 items-center'}>
                                                            <span>I wysłana o godzinię </span>
                                                            <span className={'font-bold'}>{time}</span>
                                                        </div>
                                                    </When>
                                                </div>
                                            </div>

                                        </div>
                                        <div className={'flex items-end justify-end mt-10'}>
                                            <DangerButton label={'Anuluj'} press={() => {
                                                setDate(null!);
                                                setChannel(null);
                                            }} loading={false}/>
                                            <SuccessButton label={'Zaplanuj'} press={() => {
                                                save()
                                            }} loading={false}/>
                                        </div>
                                    </div>
                                </>) : <>
                                    <Calendar plannedMessage={plannedMessages} setDate={(date: any) => {
                                        setDate(date);
                                    }} channels={channels}/>
                                </>}
                            </div>
                        ) : <NoAccess/>}
                    </div>
                </section>
            </div>
        </>
    );
});
