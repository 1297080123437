import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {useStore} from "../store/store";
import {observer} from "mobx-react";
import {NoAccess} from "../components/NoAccess";
import {Input, Option, Select, Textarea, Tooltip} from "@material-tailwind/react";
import {
    RiHistoryLine as HistoryLineIcon,
} from '@remixicon/react'
import {ReportBugModal} from "../components/modal/ReportBugModal";
import {useParams} from "react-router";
import {useSearchParams} from "react-router-dom";


export const BugPage = observer(() => {
    const { t, i18n } = useTranslation();
    const store = useStore();
    let params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [bugs, setBugs] = useState([]);
    const [mode, setMode] = useState('1');
    const [selectedBug, setSelectedBug] = useState({
        open: false,
        data: null
    })
    if(store.user == null){
        return <NoAccess/>;
    }
    const setModeD = (type: string) =>{
        setMode(type);
        store.getReportedBug(type).then(r => {
            setBugs(r);
            if(params.id !=null){
                setSelectedBug({open: true, data: r.find((e: any)=> e.id === params.id)!})
                setSearchParams({})
            }
        }).finally(()=> {
        })
    }
    useEffect(()=> {
        if(searchParams.get('status') !=null){
            setModeD(searchParams.get('status')!);
        }else{
            setModeD('AWAIT');
        }
        store.setLoading(false);
    }, [])
    if(store.loading) return <></>
    return (
        <>
            <div>
                <section className="xl:overflow-hidden select-none flex flex-col min-h-screen text-white">
                    <div className={"flex flex-col items-center h-full text-xl text-white font-montserrat top-40"}>
                        {(
                            <div className={"h-full w-screen relative"}>
                                <div className={"mt-28 mx-5 xl:mx-28"}>
                                    <div className={'flex justify-between items-center'}>
                                        <div className={"text-4xl font-bold"}>Zgłoszone błędy</div>
                                        <div className={'flex space-x-2'}>
                                            <Select className={'text-white font-montserrat'}
                                                    labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                    menuProps={{className: 'bg-black'}}
                                                    color={'blue'}
                                                    value={mode}
                                                    label={'Domyślny status błędu'}
                                                    onChange={(e: any) => setModeD(e)}>
                                                <Option value={'AWAIT'}>Oczekuję na weryfikację</Option>
                                                <Option value={'ACTIVE'}>Aktywne</Option>
                                                <Option value={'RESOLVED'}>Rozwiązane</Option>
                                                <Option value={'CLOSED'}>Zamknięte</Option>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className={"w-full h-1 bg-gray-700 mb-5 mt-5"}></div>
                                    <div className={"grid grid-cols-1 xl:grid-cols-2 gap-2 xl:gap-4"}>
                                        {bugs.map((e: any) => <>
                                            <div className={'bg-[#1111114a] rounded-2xl p-2 flex flex-col xl:flex-row justify-between'}>
                                                <div>
                                                    <div className={'flex space-x-1 items-center'}>
                                                        <span
                                                            className={'text-sm font-montserrat'}>Zgłoszono przez:</span>
                                                            <div className={'flex items-center space-x-1 text-sm'}>
                                                                <img
                                                                className={"h-5 rounded-2xl"}
                                                                onError={({currentTarget}) => {
                                                                    currentTarget.onerror = null;
                                                                    currentTarget.src = 'https://cdn.discordapp.com/avatars/643945264868098049/c6a249645d46209f337279cd2ca998c7.webp'
                                                                }}
                                                                src={e.user.avatar != null ? `https://cdn.discordapp.com/avatars/${e.user.id}/${e.user.avatar}` : 'https://cdn.discordapp.com/avatars/643945264868098049/c6a249645d46209f337279cd2ca998c7.webp'}/>
                                                            <span className={'font-black'}>{e.user.username}</span>
                                                            <div
                                                                className={'text-xs text-gray-500 font-montserrat'}>{new Date(e.created_at).toLocaleDateString()} {new Date(e.created_at).toLocaleTimeString()}</div>
                                                        </div>
                                                    </div>
                                                    <div className={'flex space-x-1 items-center'}>
                                                    <span
                                                        className={'text-sm font-montserrat'}>Platforma urządzenia:</span>
                                                        <span className={'text-xs text-gray-500'}>{e.platform}</span>
                                                    </div>
                                                </div>
                                                <Tooltip content={'Zobacz więcej'} placement={'bottom-start'}>
                                                    <div onClick={()=> setSelectedBug({open: true, data: e})}  className={'cursor-pointer'}><HistoryLineIcon color={'orange'}/></div>
                                                </Tooltip>
                                            </div>
                                        </>)}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </div>
            <ReportBugModal open={selectedBug.open} setOpen={(b: boolean)=> setSelectedBug({open: b, data: selectedBug.data})} data={selectedBug.data} refresh={()=> setModeD(mode)}/>
        </>
    );
});
