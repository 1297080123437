import React from "react";
import {DangerButton} from "./ui/DangerButton";
import {useTranslation} from "react-i18next";

export const UserHistoryItem = (props: {user: {id: string, name: string, avatarURL: string}, manage: Function}) => {
    return (<>
        <div className={"bg-[#111111] block rounded-2xl shadow-lg p-4 w-full font-montserrat h-12/12"}>
            <img
                className={"h-24 mx-auto rounded-2xl"}
                src={props.user.avatarURL}
                onError={({currentTarget}) => {
                    currentTarget.onerror = null;
                    currentTarget.src = 'https://cdn.discordapp.com/avatars/643945264868098049/c6a249645d46209f337279cd2ca998c7.webp'
                }}/>

            <div className={'flex flex-col justify-between h-max text-center'}>
                <div>
                    <div className={"text-2xl font-bold mt-2"}>{props.user.name}</div>
                </div>
                <div className={"flex items-end justify-center mt-10"}>
                    <DangerButton label={'Zarządzaj'} press={()=> props.manage(props.user.id)} loading={false}/>
                </div>
            </div>
        </div>
    </>);
}
