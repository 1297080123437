import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import banner from "../assets/banner.png";
import {useStore} from "../store/store";
import {observer} from "mobx-react";
import {DebugType} from "../components/model/Snackbar";
import {Option, Select} from "@material-tailwind/react";
import {When} from "react-if";


export const Leaderboard = observer(() => {
    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(false);
    const [load, setLoad] = useState(false);
    const [type, setType] = useState('LEVEL')
    const store = useStore();
    const [users, setUsers] = useState([]);
    const jumpToSearch = () => {
        document.getElementById("result-search")!.scrollIntoView({
            behavior: "smooth",
        });
    };
    useEffect(() => {
        setTypeD('LEVEL')
    }, [])
    const setTypeD = (type: string) =>{
        setType(type);
        setLoad(true);
        store.getLeaderboard(type, 1).then(r => {
            setUsers(r);
            setLoad(false);
        }).finally(()=> {
            jumpToSearch();
        })
    }
    return (
        <>
            <div className={'w-full h-full bg-white'}>
                <div className={'bg-[#D9D9D9] font-montserrat flex flex-col items-center justify-center space-y-1 p-10'}>
                    <div className={'uppercase font-black text-3xl'}>{t('landing.leaderboard')}</div>
                    <div className={'uppercase text-xl'}>{t('landing.leaderboardDescription')}</div>
                    <div className={'w-1/2'}>
                        <div className={'mt-12'}>
                            <Select className={'text-black font-montserrat mx-auto'}
                                    labelProps={{className: 'text-black peer-placeholder-shown:text-black'}}
                                    menuProps={{className: 'bg-white'}}
                                    color={'blue'}
                                    label={t('landing.leaderboardSelectType')}
                                    onChange={(e: any) => setTypeD(e)}
                            >
                                <Option value={'LEVEL'} className={'flex items-center gap-2'}>{t('landing.leaderboardLevel')}</Option>
                                <Option value={'GCOINS'} className={'flex items-center gap-2'}>{t('landing.leaderboardGCoins')}</Option>
                            </Select>
                        </div>
                    </div>
                </div>
                <div className={'mt-5 text-center mx-2'}>
                    <div id={'result-search'} className={'uppercase font-black text-3xl font-montserrat'}>{t('landing.leaderboardResult')}</div>
                    <div className={'grid grid-cols-3 h-auto gap-2'}>
                        {users.map((e: any, index) => <>
                            <div className={'bg-[#D9D9D9] flex p-2 space-x-2 rounded-sm items-center'}>
                                <div
                                    className={'text-xl bg-red-500 px-2 py-1 text-white rounded-2xl'}>#{index + 1}</div>
                                <img
                                    className={"h-16 md:h-16 rounded-2xl"}
                                    onError={({currentTarget}) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = 'https://cdn.discordapp.com/avatars/643945264868098049/c6a249645d46209f337279cd2ca998c7.webp'
                                    }}
                                    src={e.avatar != null ? `https://cdn.discordapp.com/avatars/${e.id}/${e.avatar}` : 'https://cdn.discordapp.com/avatars/643945264868098049/c6a249645d46209f337279cd2ca998c7.webp'}/>
                                <div className={'flex flex-col items-start'}>
                                    <div>{e.username}</div>
                                    <When condition={type == 'LEVEL'}>
                                        <div>Poziom: {e.level}</div>
                                    </When>
                                    <When condition={type == 'GCOINS'}>
                                        <div>gCoiny: {e.gCoins}</div>
                                    </When>
                                </div>
                            </div>
                        </>)}
                    </div>
                </div>
            </div>
        </>
    )
        ;
});
