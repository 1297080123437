import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import banner from "../assets/banner.png";
import {useStore} from "../store/store";
import {observer} from "mobx-react";
import {DebugType} from "../components/model/Snackbar";
import {UserItem} from "../components/UserItem";


export const LandingPage = observer(() => {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(false);
  const store = useStore();
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const jumpToSearch = () => {
    document.getElementById("result-search")!.scrollIntoView({
      behavior: "smooth",
    });
  };
  const searchM = () =>{
    if(search.length < 1) {
      store.addSnackbar({
        message: {
          pl: "To polę nie może być pustę",
          en: 'This input cannot be empty'
        },
        type: DebugType.WARN,
        duration: 10000
      })
      return;
    }
    setLoad(true);
    store.searchProfiles(search).then(r => {
      setUsers(r);
      setLoad(false);
    }).finally(()=> {
      jumpToSearch();
    })
  }
  return (
      <>
        <div>
          <div className={"w-full h-full z-0"}>
            <div className="relative xl:block h-[90%]">
              <iframe loading={'lazy'} className={"wave mobile-wave block h-[95dvh] w-full"}></iframe>
              <div className="tccx text-white flex flex-col justify-between h-full">
                <div className="text-center flex flex-col items-center h-max">
                  <div className="flex flex-col flex-grow py-44 items-center ">
                    <div className="flex flex-col mb-14">
                      <div className={'flex flex-row items-center space-x-7'}>
                        <img src={banner} loading={'lazy'} className="h-8 xl:h-20" alt="Banner"/>
                        <div className="text-2xl xl:text-4xl font-bold font-montserrat">
                          x
                        </div>
                        <img
                            src="https://cdn.prod.website-files.com/6257adef93867e50d84d30e2/636e0b52aa9e99b832574a53_full_logo_blurple_RGB.png"
                            alt="discord"
                            className="h-5 xl:h-11"
                            loading={'lazy'}
                        />
                      </div>
                      <div className={'uppercase font-montserrat -mt-3'}>go.genesis-zone.com/discord</div>
                    </div>
                    <div className="text-sm xl:text-xl uppercase font-montserrat">
                      {t('landing.firstLine')}
                    </div>
                    <div className="text-2xl xl:text-4xl mt-2 font-black uppercase font-montserrat">
                      {t('landing.secondLine')}
                    </div>
                    <div className="text-lg xl:text-4xl uppercase font-montserrat">
                      {t('landing.thirdLine')}
                    </div>
                    <a
                        className="flex items-center mt-24 justify-center text-white uppercase px-8 py-2 text-2xl font-montserrat border border-white rounded-sm hover:opacity-75 hover:text-white"
                        href={'https://discord.gg/genesis-zone-784111179479515198'}
                        target="_blank"
                    >
                      {t('button.join')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'bg-[#D9D9D9] font-montserrat flex flex-col items-center justify-center space-y-1 p-10'}>
            <div className={'uppercase font-black text-3xl'}>{t('landing.searchProfile')}</div>
            <div className={'uppercase text-xl'}>{t('landing.searchProfileInfo')}</div>
            <div className={'w-full mt-12'}>
              <input onInput={((e: any) => setSearch(e.target.value))} onKeyPress={(e: any) => {
                if (e.key === 'Enter') searchM()
              }} type="text" placeholder={'Enter username or global name'}
                     className="xl:bg-gray-50 border mt-12 mb-8 mx-auto xl:border-gray-300 xl:text-gray-900 text-sm placeholder:text-center rounded-sm xl:focus:ring-blue-500 xl:focus:border-blue-500 block w-1/2 p-2.5 laceholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"/>
            </div>
          </div>
          <div className={'mt-10 text-center'}>
            <div className={'uppercase font-black text-3xl font-montserrat'}>{t('landing.searchResult')}</div>
            <div className={"grid grid-cols-1 xl:grid-cols-3 gap-10 mx-5 md:mx-20 my-5 md:my-20"} id={'result-search'}>
              {users.map((e: any) => (<>
                <div key={e.id}>
                  <UserItem user={e}/>
                </div>
              </>))}
            </div>
          </div>
        </div>
      </>
  )
      ;
});
