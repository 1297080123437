import React from "react";
import {DangerButton} from "./ui/DangerButton";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import {SuccessButton} from "./ui/SuccessButton";
import {EditButton} from "./ui/EditButton";
import {
    RiPlaneLine as SendIcon,
    RiEditBoxLine as EditIcon,
    RiDeleteBin2Line as DeleteIcon,
} from '@remixicon/react'

export const PlannedMessageComponent = (props: {message: any, channel: any, sendNow: Function, cancelSend: Function}) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    return (<>
        <div className={`${props.message.sent ? 'bg-[#04ff002a]' : 'bg-[#ff00002a]'} z-0 flex justify-between items-center rounded-lg shadow-lg p-2 w-full`}>
            <div className={'text-xs p-1 px-4'}>
                <div
                    className={'font-black'}>{new Date(props.message.deliveryDate).getHours()}:{("0" + new Date(props.message.deliveryDate).getMinutes()).slice(-2)}</div>
                <div>{props.channel.name}</div>
            </div>
            <div className={'flex items-center space-x-5'}>
                <div onClick={() => {
                    props.sendNow(props.message);
                }} className={'cursor-pointer'}>
                    <SendIcon color={'green'}/>
                </div>
                <div onClick={() => {
                    navigate({pathname: '/planned-message/' + props.message.id})
                }} className={'cursor-pointer'}>
                    <EditIcon color={'orange'}/>
                </div>
                <div onClick={() => {
                    props.cancelSend(props.message);
                }} className={'cursor-pointer'}>
                    <DeleteIcon color={'red'}/>
                </div>
            </div>
        </div>
    </>);
}
