import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Input, Option, Select,
    Textarea,
    Typography
} from "@material-tailwind/react";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import platform from "platform";
import {useStore} from "../../store/store";

export const ReportBugModal = (props: {open: boolean, setOpen: Function, data: any, refresh: Function}) => {
    const { t, i18n } = useTranslation();
    const handleOpen = () => props.setOpen(!props.open);
    const store = useStore();
    if(props.data == null) {
        return <></>
    }
    const save = (e: string) => {
        store.updateReportBug(props.data.id, e).then(()=> {
            props.refresh();
            handleOpen();
        })
    }
    return (<>
        <Dialog open={props.open} handler={handleOpen} className={"bg-[#111111] "} size={"md"}>
            <DialogHeader className={"text-white font-montserrat text-3xl"}>{t('bug.reportedTitle')}</DialogHeader>
            <DialogBody className={'text-white'}>
                <div className={'mb-5'}>{t('bug.reportedDescription')}</div>
                <div className={'flex space-x-1 items-center'}>
                    <span className={'text-sm font-montserrat'}>Zgłoszono przez:</span>
                    <div className={'flex items-center space-x-1 text-sm'}>
                        <img
                            className={"h-5 rounded-2xl"}
                            onError={({currentTarget}) => {
                                currentTarget.onerror = null;
                                currentTarget.src = 'https://cdn.discordapp.com/avatars/643945264868098049/c6a249645d46209f337279cd2ca998c7.webp'
                            }}
                            src={props.data.user.avatar != null ? `https://cdn.discordapp.com/avatars/${props.data.user.id}/${props.data.user.avatar}` : 'https://cdn.discordapp.com/avatars/643945264868098049/c6a249645d46209f337279cd2ca998c7.webp'}/>
                        <span className={'font-black'}>{props.data.user.username}</span>
                        <div
                            className={'text-xs text-gray-500 font-montserrat'}>{new Date(props.data.created_at).toLocaleDateString()} {new Date(props.data.created_at).toLocaleTimeString()}</div>
                    </div>
                </div>
                <div className={'flex space-x-1 items-center'}>
                    <span className={'text-sm font-montserrat'}>Platforma urządzenia:</span>
                    <span className={'text-xs text-gray-500'}>{props.data.platform}</span>
                </div>
                <div
                    className={'text-sm font-montserrat whitespace-pre-wrap'}>{props.data.description}</div>
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    color="red"
                    onClick={handleOpen}
                    className="mr-1"
                >
                    <span>{t('bug.cancelButton')}</span>
                </Button>
                <div className={"inline w-1/2"}>
                    <Select className={'text-white font-montserrat'}
                            labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                            menuProps={{className: 'bg-black'}}
                            value={props.data.status}
                            color={'blue'}
                            label={t('bug.changeStatus')}
                            onChange={(e: any) => {
                                save(e);
                            }}>
                        <Option value={'AWAIT'}>Oczekuję na weryfikację</Option>
                        <Option value={'ACTIVE'}>Aktywne</Option>
                        <Option value={'RESOLVED'}>Rozwiązane</Option>
                        <Option value={'CLOSED'}>Zamknięte</Option>
                    </Select>
                </div>
            </DialogFooter>
        </Dialog>
    </>);
}
