import React, { useState} from 'react';
import {observer} from "mobx-react";
import {CalendarDay} from "../ui/CalendarDay";
import {DayViewModal} from "../modal/DayViewModal";
import {
    RiArrowLeftLine as ArrowLeft,
    RiArrowRightLine as ArrowRight
} from '@remixicon/react';
export const Calendar = observer((props: {plannedMessage: [], channels: [], setDate: Function}) => {
    const [weekdays, setWeekdays] = useState(['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela']);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);
    const [months, setMoths] = useState(["Styczeń",
        "Luty",
        "Marzec",
        "Kwiecień",
        "Maj",
        "Czerwiec",
        "Lipiec",
        "Sierpień",
        "Wrzesień",
        "Październik",
        "Listopad",
        "Grudzień",])
    const [currentDay, setCurrentDay] = useState(new Date())

    const changeCurrentDay = (day: any) => {
        setCurrentDay(day);
        props.setDate(day);
    }

    const nextMonth = () => {
        setCurrentDay(new Date(currentDay.setMonth(currentDay.getMonth() + 1)));
    }

    const previousMonth = () => {
        setCurrentDay(new Date(currentDay.setMonth(currentDay.getMonth() - 1)));
    }

    return (<>
        <div className={'pb-10'}>
            <div className="flex items-center justify-center text-4xl font-black mb-10">
                <h2>{months[currentDay.getMonth()]} {currentDay.getFullYear()}</h2>
            </div>
            <div className={'flex items-center justify-end space-x-2 mx-40 my-16'}>
                <div className={'cursor-pointer'} hidden={(new Date().getMonth() >= currentDay.getMonth() && new Date().getFullYear() == currentDay.getFullYear())} onClick={()=> previousMonth()}>
                    <ArrowLeft/>
                </div>
                <div className={'w-44'}>
                    {months[currentDay.getMonth()]} {currentDay.getFullYear()}
                </div>
                <div className={'cursor-pointer'} onClick={()=> nextMonth()}>
                    <ArrowRight/>
                </div>
            </div>
            <div className="mx-40">
                <div className="grid grid-cols-7 text-center">
                    {
                        weekdays.map((weekday) => {
                            return <div className="weekday"><p>{weekday}</p></div>
                        })
                    }
                </div>
                <CalendarDay plannedMessage={props.plannedMessage} channels={props.channels} day={currentDay} changeCurrentDay={changeCurrentDay} setData={(e: any)=>{
                    setData(e);
                    setOpen(true);
                }}/>
            </div>
        </div>
        <DayViewModal open={open} setOpen={setOpen} data={data}/>
    </>)
});
