import React from "react";
import {DangerButton} from "./ui/DangerButton";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import {When} from "react-if";

export const UserItem = (props: {user: any}) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    return (<>
        <div className={"bg-[#D9D9D9] block rounded-2xl shadow-lg p-2 md:p-4 w-full font-montserrat h-12/12"}>
            <div className={'w-full h-48'}>
                <div className={'rounded-2xl relative w-auto h-36 mx-6'}
                     style={{
                         backgroundImage: props.user.banner != null ? `url(https://cdn.discordapp.com/banners/${props.user.id}/${props.user.banner}?size=1024)` : undefined,
                         backgroundSize: 'cover',
                         backgroundRepeat: "no-repeat",
                         backgroundPosition: "center",
                         backgroundColor: props.user.bannerColor != null ? `#${props.user.bannerColor.toString(16)}` : '#FF3939'
                     }}>
                    <img
                        className={"-bottom-16 md:-bottom-20 absolute h-20 md:h-24 rounded-2xl left-[50%] translate-y-[-50%] translate-x-[-50%]"}
                        src={props.user.avatar != null ? `https://cdn.discordapp.com/avatars/${props.user.id}/${props.user.avatar}` : 'https://cdn.discordapp.com/avatars/643945264868098049/c6a249645d46209f337279cd2ca998c7.webp'}
                        onError={({currentTarget}) => {
                            currentTarget.onerror = null;
                            currentTarget.src = 'https://cdn.discordapp.com/avatars/643945264868098049/c6a249645d46209f337279cd2ca998c7.webp'
                        }}/>
                </div>
            </div>
            <div className={'flex flex-col justify-between h-32'}>
                <div className={'space-y-reverse'}>
                    <When condition={props.user.global_name != null}>
                        <div
                            className={"text-2xl md:text-3xl font-extrabold"}>{props.user.global_name != null ? props.user.global_name : "\u200B"}</div>
                        <div className={"text-xl md:text-2xl font-normal -mt-2"}>{props.user.username}</div>
                    </When>
                    <When condition={props.user.global_name == null}>
                        <div
                            className={"text-2xl md:text-3xl font-extrabold"}>{props.user.username}</div>
                    </When>
                </div>
                <div className={"flex items-end justify-center mb-4 md:mb-2"}>
                    <DangerButton label={t('button.checkProfile')} press={() => {
                        navigate({pathname: '/profile/' + props.user.id})
                    }} loading={false}/>
                </div>
            </div>
        </div>
    </>);
}
