import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter, createBrowserRouter, Route, Routes} from "react-router-dom";
import {store, StoreProvider} from "./store/store";
import { LandingPage } from "./pages/LandingPage";
import config from "./config.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as pl from "./lang/pl-PL.json";
import * as en from "./lang/en-EN.json";
import {ConfigurationPage} from "./pages/ConfigurationPage";
import Loader from "./components/Loader";
import {Layout} from "./components/Layout";
import {NotFound} from "./pages/NotFound";
import {LoginPage} from "./pages/LoginPage";
import {ProfilePage3} from "./pages/ProfilePage3";
import {SettingsPage} from "./pages/SettingsPage";
import {TicketAnalytic} from "./pages/TicketAnalytic";
import {DeveloperPage} from "./pages/DeveloperPage";
import {ManageUser} from "./pages/ManageUser";
import {PlannedMessage} from "./pages/PlannedMessage";
import {PlannedMessageEdition} from "./pages/PlannedMessageEdition";
import {Leaderboard} from "./pages/Leaderboard";
import {BugPage} from "./pages/BugPage";

Object.assign(window, {
  GENESISZONE: {
    config: config,
    languages: {
      pl,
      en,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
i18n.use(initReactI18next).init({
  resources: {
    pl: {
      translation: pl,
    },
    en: {
      translation: en,
    },
  },
  lng: localStorage.getItem('lang') ?? "pl",
  fallbackLng: "pl",
  interpolation: {
    escapeValue: false,
  },
});


root.render(
    <BrowserRouter>
      <StoreProvider>
        <Suspense fallback={<Loader/>}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route key={"LandingPage"} path="/" element={<LandingPage />}/>
              <Route key={"Leaderboard"} path="/leaderboard" element={<Leaderboard />}/>
              <Route key={"BugPage"} path="/bug" element={<BugPage />}/>
              <Route key={"BugPageView"} path="/bug/:id" element={<BugPage />}/>
              <Route key={"NotFound"} path="*" element={<NotFound />}/>
              <Route key={"LoginPage"} path="/login" element={<LoginPage />} />
              <Route key={"ProfilePage3"} path="/profile/:id" element={<ProfilePage3/>} />
              <Route key={"SettingsPage"} path="/settings" element={<SettingsPage/>} />
              <Route key={"TicketAnalytic"} path="/ticket-analytic" element={<TicketAnalytic/>} />
              <Route key={"DeveloperPage"} path="/developer" element={<DeveloperPage/>} />
              <Route key={"ManageUser"} path="/manage-user" element={<ManageUser/>} />
              <Route key={"Configuration"} path="/configuration" element={<ConfigurationPage/>} />
              <Route key={"PlannedMessage"} path="/planned-message" element={<PlannedMessage/>} />
              <Route key={"PlannedMessageEdition"} path="/planned-message/:id" element={<PlannedMessageEdition/>} />
            </Route>
            {/* <Route path='/app' element={<ProtectedRoute><Layout /></ProtectedRoute>} >
            <Route index element={<App />} />
          </Route> */}
          </Routes>
        </Suspense>
      </StoreProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
