import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Input,
    Textarea,
    Typography
} from "@material-tailwind/react";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import platform from "platform";
import {useStore} from "../../store/store";

export const BugReportModal = (props: {open: boolean, setOpen: Function}) => {
    const { t, i18n } = useTranslation();
    const handleOpen = () => props.setOpen(!props.open);
    const [platformData, setPlatformData] = useState(`${platform.name} ${platform.version} [${platform.os.family} ${platform.os.version} x${platform.os.architecture}]`);
    const [description, setDescription] = useState("");
    const store = useStore();
    const save = () => {
        store.reportBug({platform: platformData, description: description}).finally(()=> {
            props.setOpen(!props.open);
            setDescription("")
        })
    }
    return (<>
        <Dialog open={props.open} handler={handleOpen} className={"bg-[#111111] "} size={"md"}>
            <DialogHeader className={"text-white font-montserrat text-3xl"}>{t('bug.reportTitle')}</DialogHeader>
            <DialogBody className={'text-white'}>
                <div className={'mb-5'}>{t('bug.reportDescription')}</div>
                <div className={'space-y-3'}>
                    <Input value={platformData} labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                           label={t('bug.platform')} color={'blue'} crossOrigin={''}
                           disabled={store.user && (store.user.permission & 2097152) != 2097152}
                           onBlur={(e: any) => {
                               setPlatformData(e.target.value);
                           }} onPaste={(e: any) => {
                        setPlatformData(e.target.value);
                    }} className="text-white disabled:bg-transparent"/>
                    <Textarea defaultValue={description}
                              labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                              label={t('bug.description')} color={'blue'}
                              disabled={store.user && (store.user.permission & 2097152) != 2097152}
                              onBlur={(e: any) => {
                                  setDescription(e.target.value);
                              }} onPaste={(e: any) => {
                        setDescription(e.target.value);
                    }} className="text-white "/>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    color="red"
                    onClick={handleOpen}
                    className="mr-1"
                >
                    <span>{t('bug.cancelButton')}</span>
                </Button>
                <Button variant="gradient" color="green" onClick={save}>
                    <span>{t('bug.sendButton')}</span>
                </Button>
            </DialogFooter>
        </Dialog>
    </>);
}
