import {useTranslation} from "react-i18next";
import {RiCloseCircleLine as MinusIcon} from '@remixicon/react'
import {When} from "react-if";
import {useStore} from "../store/store";
export const BadgeItem = (props: {badge: any, remove?: Function, debug?: boolean}) => {
    const { t, i18n } = useTranslation();
    const store = useStore();
    return (
        <>
            <div className={'flex items-center space-x-5'}>
                <When condition={props.remove != undefined && store.user && (store.user.permission & 65536) == 65536}>
                    <div className={'cursor-pointer'} onClick={() => props.remove!()}>
                        <MinusIcon color={'red'}/>
                    </div>
                </When>
                <div className={"flex items-center"}>
                    <img className={"h-10 mx-3"}
                         src={`https://cdn.discordapp.com/emojis/${props.badge.emoji}.webp?size=96`}/>
                    <div>
                        <When condition={props.debug}>
                            <div
                                className={"text-xl font-bold font-montserrat"}>PL: {props.badge.name['pl']}
                            </div>
                            <div
                                className={"text-xl font-bold font-montserrat"}>EN: {props.badge.name['en']}
                            </div>
                        </When>
                        <When condition={!props.debug}>
                            <div
                                className={"text-xl font-bold font-montserrat"}>{props.badge.name[i18n.language]}
                            </div>
                        </When>
                        <div
                            className={"text-gray-500 text-lg font-semibold"}>{t('profile.badges.' + props.badge.type)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
